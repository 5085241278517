import { getHeader, userTokenUrl } from "@/constant/config"
import { createRouter, createWebHistory } from "vue-router"
import { menuHeader } from "@/constant/data";
import axios from 'axios'

const routes = [
  { path: "/login", name: 'login', component: () => import("@/views/auth/login/login.vue"), meta: { requiresAuth: false } },
  {
    path: "/", name: "Layout", redirect: "/home", component: () => import("@/Layout/index.vue"),
    children: [
      { path: "/home", name: "home", component: () => import("@/views/home/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Home' } },
      // User
      { path: "/user", name: "user", component: () => import("@/views/user/index.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/user/add", name: "user-add", component: () => import("@/views/user/user-add.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/user/:id", name: "user-edit", component: () => import("@/views/user/user-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'User' } },
      // ROle
      { path: "/user/role", name: "user-role", component: () => import("@/views/user/role/index.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      // Profile
      { path: "/profile", name: "profile", component: () => import("@/views/profile/index.vue"), props: true, meta: { requiresAuth: true, hide: true, groupParent: [{ name: "Master", route: null }, { name: "user", route: 'user' }] } },
      // Organization
      { path: "/organization/print", name: "organization-print", component: () => import("@/views/organization/print/index.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/organization/print/new", name: "organization-print-new", component: () => import("@/views/organization/print/organization-print-add.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/organization/print/:id", name: "organization-print-edit", component: () => import("@/views/organization/print/organization-print-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'User' } },
      // Organization
      { path: "/organization", name: "organization", component: () => import("@/views/organization/index.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/organization/new", name: "organization-new", component: () => import("@/views/organization/organization-add.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/organization/:id", name: "organization-edit", component: () => import("@/views/organization/organization-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'User' } },
      // Branch
      { path: "/branch", name: "branch", component: () => import("@/views/branch/index.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/branch/new", name: "branch-new", component: () => import("@/views/branch/branch-add.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/branch/:id", name: "branch-edit", component: () => import("@/views/branch/branch-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'User' } },
      // Branch
      { path: "/warehouse", name: "warehouse", component: () => import("@/views/warehouse/index.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/warehouse/new", name: "warehouse-new", component: () => import("@/views/warehouse/warehouse-add.vue"), meta: { requiresAuth: true, hide: true, group: 'User' } },
      { path: "/warehouse/:id", name: "warehouse-edit", component: () => import("@/views/warehouse/warehouse-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'User' } },
      // Employee Department
      { path: "/employee/department", name: "employee-department", component: () => import("@/views/employee/department/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Employee' } },
      // Employee Position
      { path: "/employee/position", name: "employee-position", component: () => import("@/views/employee/position/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Employee' } },
      // Employee
      { path: "/employee", name: "employee", component: () => import("@/views/employee/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Employee' } },
      { path: "/employee/new", name: "employee-new", component: () => import("@/views/employee/employee-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Employee' } },
      { path: "/employee/:id", name: "employee-edit", component: () => import("@/views/employee/employee-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Employee' } },
      // Payment Method
      { path: "/payment/method", name: "payment-method", component: () => import("@/views/payment-method/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/payment/method/new", name: "payment-method-new", component: () => import("@/views/payment-method/payment-method-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/payment/method/:id", name: "payment-method-edit", component: () => import("@/views/payment-method/payment-method-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Bank
      { path: "/bank", name: "bank", component: () => import("@/views/bank/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/bank/new", name: "bank-new", component: () => import("@/views/bank/bank-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/bank/:id", name: "bank-edit", component: () => import("@/views/bank/bank-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Vendor
      { path: "/vendor", name: "vendor", component: () => import("@/views/contact/vendor/index.vue"), meta: { requiresAuth: true, hide: true, permission: 'vendor-view', group: 'Purchase' } },
      { path: "/vendor/new", name: "vendor-new", component: () => import("@/views/contact/vendor/vendor-add.vue"), meta: { requiresAuth: true, permission: 'vendor-view', hide: true, group: 'Purchase' } },
      { path: "/vendor/:id", name: "vendor-edit", component: () => import("@/views/contact/vendor/vendor-edit.vue"), props: true, meta: { requiresAuth: true, permission: 'vendor-view', hide: true, group: 'Purchase' } },
      // Tax
      { path: "/tax", name: "tax", component: () => import("@/views/tax/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/tax/new", name: "tax-new", component: () => import("@/views/tax/tax-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/tax/:id", name: "tax-edit", component: () => import("@/views/tax/tax-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // State
      { path: "/state", name: "state", component: () => import("@/views/state/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/state/new", name: "state-new", component: () => import("@/views/state/state-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/state/:id", name: "state-edit", component: () => import("@/views/state/state-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // City
      { path: "/city", name: "city", component: () => import("@/views/city/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/city/new", name: "city-new", component: () => import("@/views/city/city-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/city/:id", name: "city-edit", component: () => import("@/views/city/city-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Notification Template
      { path: "/notification/template", name: "notification-template", component: () => import("@/views/notification-template/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/notification/template/new", name: "notification-template-new", component: () => import("@/views/notification-template/notification-template-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/notification/template/:id", name: "notification-template-edit", component: () => import("@/views/notification-template/notification-template-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Notification
      { path: "/notification", name: "notification", component: () => import("@/views/notification/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/notification/new", name: "notification-new", component: () => import("@/views/notification/notification-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/notification/:id", name: "notification-edit", component: () => import("@/views/notification/notification-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Currency
      { path: "/currency", name: "currency", component: () => import("@/views/currency/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/currency/new", name: "currency-new", component: () => import("@/views/currency/currency-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/currency/:id", name: "currency-edit", component: () => import("@/views/currency/currency-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Exchange
      { path: "/exchange", name: "exchange", component: () => import("@/views/exchange/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/exchange/new", name: "exchange-new", component: () => import("@/views/exchange/exchange-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/exchange/:id", name: "exchange-edit", component: () => import("@/views/exchange/exchange-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // UoM
      { path: "/uom", name: "uom", component: () => import("@/views/uom/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/uom/new", name: "uom-new", component: () => import("@/views/uom/uom-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/uom/:id", name: "uom-edit", component: () => import("@/views/uom/uom-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Product Cost
      { path: "/product/cost", name: "product-cost", component: () => import("@/views/product/cost/index.vue"), meta: { requiresAuth: true, permission: "cogs-menu", hide: true, group: 'Product' } },
      // Product Type
      { path: "/product/type", name: "product-type", component: () => import("@/views/product-type/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Product' } },
      { path: "/product/type/new", name: "product-type-new", component: () => import("@/views/product-type/product-type-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Product' } },
      { path: "/product/type/:id", name: "product-type-edit", component: () => import("@/views/product-type/product-type-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Product' } },
      // Product Category
      { path: "/product/category", name: "product-category", component: () => import("@/views/product-category/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Product' } },
      { path: "/product/category/new", name: "product-category-new", component: () => import("@/views/product-category/product-category-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Product' } },
      { path: "/product/category/:id", name: "product-category-edit", component: () => import("@/views/product-category/product-category-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Product' } },
      // Price List
      { path: "/product/price-list", name: "product-price-list", component: () => import("@/views/product/price-list/index.vue"), meta: { requiresAuth: true, permission: "price-list-menu", hide: true, group: 'Product' } },
      { path: "/product/price-list/new", name: "product-price-list-new", component: () => import("@/views/product/price-list/price-list-add.vue"), meta: { requiresAuth: true, permission: "price-list-menu", hide: true, group: 'Product' } },
      { path: "/product/price-list/:id", name: "product-price-list-edit", component: () => import("@/views/product/price-list/price-list-edit.vue"), props: true, meta: { requiresAuth: true, permission: "price-list-menu", hide: true, group: 'Product' } },
      // Product
      { path: "/product", name: "product", component: () => import("@/views/product/index.vue"), meta: { requiresAuth: true, permission: "product-menu", hide: true, group: 'Product' } },
      { path: "/product/new", name: "product-new", component: () => import("@/views/product/product-add.vue"), meta: { requiresAuth: true, permission: "product-menu", hide: true, groupParent: [{ name: "Master", route: null }, { name: "product", route: 'product' }] } },
      { path: "/product/:id", name: "product-edit", component: () => import("@/views/product/product-edit.vue"), props: true, meta: { requiresAuth: true, permission: "product-menu", hide: true, groupParent: [{ name: "Master", route: null }, { name: "product", route: 'product' }] } },
      { path: "/product/summary", name: "product-summary", component: () => import("@/views/product/summary-table.vue"), meta: { requiresAuth: true, hide: true, groupParent: [{ name: "Transaction", route: null }, { name: "product", route: 'product' }] } },

      // // Product 2 (test)
      // { path: "/product", name: "product", component: () => import("@/views/product/index.vue"), meta: {  requiresAuth: true, hide: true } },
      // { path: "/product/new", name: "product-new", component: () => import("@/views/product/product-add.vue"), meta: { requiresAuth: true, hide: true, groupParent: [{ name: "Master", route: null }, { name: "product", route: 'product'}] }},
      // { path: "/product/:id", name: "product-edit", component: () => import("@/views/product/product-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, groupParent: [{ name: "Master", route: null }, { name: "product", route: 'product'}] } },
      // { path: "/product/summary", name: "product-summary", component: () => import("@/views/product/summary-table.vue"), meta: { requiresAuth: true, hide: true, groupParent: [{ name: "Transaction", route: null }, { name: "product", route: 'product'}] } },

      // Setting
      { path: "/setting", name: "setting", component: () => import("@/views/setting/index.vue"), meta: {  requiresAuth: true, hide: true } },
      // Promo
      { path: "/setting/promo", name: "promo", component: () => import("@/views/promo/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Setting' } },
      { path: "/setting/promo/new", name: "setting-promo-new", component: () => import("@/views/promo/promo-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Setting' } },
      { path: "/setting/promo/:id", name: "setting-promo-edit", component: () => import("@/views/promo/promo-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Setting' } },
      // Approval
      { path: "/setting/approval/type", name: "setting-approval-type", component: () => import("@/views/approval/type/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Setting' } },
      // Customer
      { path: "/customer", name: "customer", component: () => import("@/views/contact/customer/index.vue"), meta: { requiresAuth: true, permission: "customer-menu", hide: true, group: 'Sale' } },
      { path: "/customer/new", name: "customer-new", component: () => import("@/views/contact/customer/customer-add.vue"), meta: { requiresAuth: true, permission: "customer-menu", hide: true, group: 'Sale' } },
      { path: "/customer/:id", name: "customer-edit", component: () => import("@/views/contact/customer/customer-edit.vue"), props: true, meta: { requiresAuth: true, permission: "customer-menu", hide: true, group: 'Sale' } },
      // Main Menu Grid
      { path: "/purchase/menu", name: "purchase-menu", component: () => import("@/views/menu-grid/purchase-menu.vue"), meta: { requiresAuth: true, permission: 'purchase-view', hide: true, group: 'Purchase' } },
      { path: "/inventory/menu", name: "inventory-menu", component: () => import("@/views/menu-grid/inventory-menu.vue"), meta: { requiresAuth: true, permission: 'inventory-view', hide: true, group: 'Inventory' } },
      { path: "/sale/menu", name: "sale-menu", component: () => import("@/views/menu-grid/sale-menu.vue"), meta: { requiresAuth: true, permission: 'sale-view', hide: true, group: 'Sale' } },
      { path: "/workshop/menu", name: "workshop-menu", component: () => import("@/views/menu-grid/workshop-menu.vue"), meta: { requiresAuth: true, permission: "workshop-view", hide: true, group: 'Workshop' } },
      { path: "/invoice/menu", name: "invoice-menu", component: () => import("@/views/menu-grid/invoice-menu.vue"), meta: { requiresAuth: true, permission: "invoice-view", hide: true, group: 'Invoice' } },
      { path: "/finance/menu", name: "finance-menu", component: () => import("@/views/menu-grid/finance-menu.vue"), meta: { requiresAuth: true, permission: "finance-view", hide: true, group: 'Finance' } },
      // Purchase Order
      { path: "/purchase/order", name: "purchase-order", component: () => import("@/views/order/index.vue"), meta: { requiresAuth: true, permission: "purchase-order-menu", hide: true, group: 'Purchase', parent: 'purchase' } },
      { path: "/purchase/order/new", name: "purchase-order-new", component: () => import("@/views/order/order-add.vue"), meta: { requiresAuth: true, permission: "purchase-order-menu", hide: true, group: 'Purchase', parent: 'purchase' } },
      { path: "/purchase/order/:id", name: "purchase-order-edit", component: () => import("@/views/order/order-edit.vue"), props: true, meta: { requiresAuth: true, permission: "purchase-order-menu", hide: true, group: 'Purchase', parent: 'purchase' } },
      // Sale Order
      { path: "/sale/order", name: "sale-order", component: () => import("@/views/order/index.vue"), meta: { requiresAuth: true, permission: "sale-order-menu", hide: true, group: 'Sale' } },
      { path: "/sale/order/new", name: "sale-order-new", component: () => import("@/views/order/order-add.vue"), meta: { requiresAuth: true, permission: "sale-order-menu", hide: true, group: 'Sale' } },
      { path: "/sale/order/:id", name: "sale-order-edit", component: () => import("@/views/order/order-edit.vue"), props: true, meta: { requiresAuth: true, permission: "sale-order-menu", hide: true, group: 'Sale' } },
      // Purchase
      { path: "/purchase", name: "purchase", component: () => import("@/views/purchase/index.vue"), meta: { requiresAuth: true, permission: "purchase-menu", hide: true, group: 'Purchase' } },
      { path: "/purchase/new", name: "purchase-new", component: () => import("@/views/purchase/purchase-add.vue"), meta: { requiresAuth: true, permission: "purchase-menu", hide: true, group: 'Purchase' } },
      { path: "/purchase/:id", name: "purchase-edit", component: () => import("@/views/purchase/purchase-edit.vue"), props: true, meta: { requiresAuth: true, permission: "purchase-menu", hide: true, group: 'Purchase' } },
      // Payment
      { path: "/payment", name: "payment", component: () => import("@/views/payment/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Payment' } },
      { path: "/payment/new", name: "payment-new", component: () => import("@/views/payment/payment-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Payment' } },
      { path: "/payment/:id", name: "payment-edit", component: () => import("@/views/payment/payment-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Payment' } },
      // Order
      { path: "/order", name: "order", component: () => import("@/views/order/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Purchase' } },
      { path: "/order/new", name: "order-new", component: () => import("@/views/order/order-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Purchase' } },
      { path: "/order/:id", name: "order-edit", component: () => import("@/views/order/order-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Purchase' } },
      // Unit
      { path: "/unit", name: "unit", component: () => import("@/views/unit/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      { path: "/unit/new", name: "unit-new", component: () => import("@/views/unit/unit-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      { path: "/unit/:id", name: "unit-edit", component: () => import("@/views/unit/unit-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      // Unit check
      { path: "/unit/check", name: "unit-check", component: () => import("@/views/unit-check/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      { path: "/unit/check/new", name: "unit-check-new", component: () => import("@/views/unit-check/unit-check-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      { path: "/unit/check/:id", name: "unit-check-edit", component: () => import("@/views/unit-check/unit-check-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      // Unit Trip
      { path: "/unit/trip", name: "unit-check", component: () => import("@/views/unit-trip/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      { path: "/unit/trip/new", name: "unit-trip-new", component: () => import("@/views/unit-trip/unit-trip-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      { path: "/unit/trip/:id", name: "unit-trip-edit", component: () => import("@/views/unit-trip/unit-trip-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      { path: "/unit/trip/monitoring", name: "unit-trip-monitoring", component: () => import("@/views/unit-trip/unit-trip-monitoring.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Unit' } },
      // Report
      { path: "/sale/report/salesman", name: "sale-report-salesman", component: () => import("@/views/sale/report/salesman.vue"), meta: { requiresAuth: true, permission: "sale-report-salesman-menu", hide: true, group: 'Sale' } },
      { path: "/sale/report/supervisor", name: "sale-report-supervisor", component: () => import("@/views/sale/report/supervisor.vue"), meta: { requiresAuth: true, permission: "sale-report-supervisor-menu", hide: true, group: 'Sale' } },
      { path: "/sale/report/branch", name: "sale-report-branch", component: () => import("@/views/sale/report/branch.vue"), meta: { requiresAuth: true, permission: "sale-report-branch-menu", hide: true, group: 'Sale' } },
      { path: "/sale/report/outstanding", name: "sale-report-outstanding", component: () => import("@/views/sale/report/sale-outstanding.vue"), meta: { requiresAuth: true, hide: true, group: 'Sale' } },
      
      // Sale Document Process 
      // { path: "/sale/document/proces", name: "sale-document-process", component: () => import("@/views/sale/document/process/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Sale' } },
      { path: "/sale/document/process/new", name: "sale-document-process-new", component: () => import("@/views/sale/document/process/sale-document-process-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Sale' } },
      { path: "/sale/document/process/:id", name: "sale-document-process-edit", component: () => import("@/views/sale/document/process/sale-document-process-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Sale' } },
      // Sale unit
      { path: "/sale/unit", name: "sale-unit", component: () => import("@/views/sale-unit/index.vue"), meta: { requiresAuth: true, permission: "sale-unit-menu", hide: true, group: 'Sale' } },
      { path: "/sale/unit/new", name: "sale-unit-new", component: () => import("@/views/sale-unit/sale-unit-add.vue"), meta: { requiresAuth: true, permission: "sale-unit-menu", hide: true, group: 'Sale' } },
      { path: "/sale/unit/:id", name: "sale-unit-edit", component: () => import("@/views/sale-unit/sale-unit-edit.vue"), props: true, meta: { requiresAuth: true, permission: "sale-unit-menu", hide: true, group: 'Sale' } },
      // Sale Document
      { path: "/sale/document", name: "sale-document", component: () => import("@/views/sale-document/index.vue"), meta: { requiresAuth: true, permission: "sale-document-menu", hide: true, group: 'Sale' } },
      { path: "/sale/document/:id", name: "sale-document-edit", component: () => import("@/views/sale-document/sale-document-edit.vue"), props: true, meta: { requiresAuth: true, permission: "sale-document-menu", hide: true, group: 'Sale' } },
      // Sale Document
      { path: "/sale/document-leasing", name: "sale-document-leasing", component: () => import("@/views/sale-document-leasing/index.vue"), meta: { requiresAuth: true, permission: "sale-document-leasing-menu", hide: true, group: 'Sale' } },
      { path: "/sale/document-leasing/:id", name: "sale-document-leasing-edit", component: () => import("@/views/sale-document-leasing/sale-document-leasing-edit.vue"), props: true, meta: { requiresAuth: true, permission: "sale-document-leasing-menu", hide: true, group: 'Sale' } },
      // Sale
      { path: "/sale", name: "sale", component: () => import("@/views/sale/index.vue"), meta: { requiresAuth: true, permission: "sale-menu", hide: true, group: 'Sale' } },
      { path: "/sale/new", name: "sale-new", component: () => import("@/views/sale/sale-add.vue"), meta: { requiresAuth: true, permission: "sale-menu", hide: true, group: 'Sale' } },
      { path: "/sale/:id", name: "sale-edit", component: () => import("@/views/sale/sale-edit.vue"), props: true, meta: { requiresAuth: true, permission: "sale-menu", hide: true, group: 'Sale' } },
      // Workshop
      { path: "/workshop", name: "workshop", component: () => import("@/views/workshop/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      { path: "/workshop/new", name: "workshop-new", component: () => import("@/views/workshop/workshop-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      { path: "/workshop/:id", name: "workshop-edit", component: () => import("@/views/workshop/workshop-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      // Workshop Order
      { path: "/workshop/order", name: "workshop-order", component: () => import("@/views/workshop/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      { path: "/workshop/order/new", name: "workshop-order-new", component: () => import("@/views/workshop/workshop-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      { path: "/workshop/order/:id", name: "workshop-order-edit", component: () => import("@/views/workshop/workshop-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      // Workshop Quotation
      { path: "/workshop/quotation", name: "workshop-quotation", component: () => import("@/views/order/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      { path: "/workshop/quotation/new", name: "workshop-quotation-new", component: () => import("@/views/order/order-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      { path: "/workshop/quotation/:id", name: "workshop-quotation-edit", component: () => import("@/views/order/order-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      //  Workshop Monitoring
      { path: "/workshop/monitoring", name: "workshop-monitoring", component: () => import("@/views/workshop/workshop-monitoring.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      // Service Checklist
      { path: "/service-checklist", name: "service-checklist", component: () => import("@/views/workshop/service-checklist/index.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      // { path: "/service-checklist/new", name: "service-checklist-new", component: () => import("@/views/workshop/service-checklist/service-checklist-add.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      // { path: "/workshop/service-checklist/:id", name: "service-checklist-edit", component: () => import("@/views/workshop/service-checklist/service-checklist-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
      // Service List
      // { path: "/workshop/service-checklist", name: "workshop-service-checklist", component: () => import("@/views/sale/index.vue"), meta: {  requiresAuth: true, hide: true } },
      // { path: "/workshop/service-checklist/new", name: "workshop-service-checklist-new", component: () => import("@/views/workshop/checklist/checklist-add.vue"), meta: { requiresAuth: true, hide: true, groupParent: [{ name: "Transaction", route: null }, { name: "workshop-service-checklist", route: 'workshop-service-checklist'}] }},
      // { path: "/workshop/service-checklist/:id", name: "workshop-service-checklist-edit", component: () => import("@/views/workshop/checklist/checklist-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, groupParent: [{ name: "Transaction", route: null }, { name: "workshop-service-checklist", route: 'workshop-service-checklist'}] } },

      // coa
      { path: "/finance/chart-of-account", name: "finance-chart-of-account", component: () => import("@/views/finance/coa/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/chart-of-account/new", name: "finance-chart-of-account-new", component: () => import("@/views/finance/coa/coa-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/chart-of-account/:id", name: "finance-chart-of-account-edit", component: () => import("@/views/finance/coa/coa-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },

      { path: "/finance/ledger", name: "finance-ledger", component: () => import("@/views/finance/ledger/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/profit-loss", name: "finance-profit-loss", component: () => import("@/views/finance/profitloss/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/trial-balance", name: "finance-trial-balance", component: () => import("@/views/finance/trial-balance/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Journal
      { path: "/finance/journal", name: "finance-journal", component: () => import("@/views/finance/journal/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/journal/new", name: "finance-journal-new", component: () => import("@/views/finance/journal/journal-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/journal/:id", name: "finance-journal-edit", component: () => import("@/views/finance/journal/journal-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Journal Template
      { path: "/finance/journal-template", name: "finance-journal-template", component: () => import("@/views/finance/journal/template/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/journal-template/new", name: "finance-journal-template-new", component: () => import("@/views/finance/journal/template/template-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/journal-template/:id", name: "finance-journal-template-edit", component: () => import("@/views/finance/journal/template/template-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Voucher Category
      { path: "/finance/voucher/category", name: "voucher-category", component: () => import("@/views/finance/voucher/category/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/voucher/category/new", name: "voucher-category-new", component: () => import("@/views/finance/voucher/category/voucher-category-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/voucher/category/:id", name: "voucher-category-edit", component: () => import("@/views/finance/voucher/category/voucher-category-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Voucher Path
      { path: "/finance/voucher/path", name: "voucher-path", component: () => import("@/views/finance/voucher/path/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/voucher/path/new", name: "voucher-path-new", component: () => import("@/views/finance/voucher/path/voucher-path-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/voucher/path/:id", name: "voucher-path-edit", component: () => import("@/views/finance/voucher/path/voucher-path-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Voucher
      { path: "/finance/voucher", name: "voucher", component: () => import("@/views/finance/voucher/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/voucher/new", name: "voucher-new", component: () => import("@/views/finance/voucher/voucher-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/voucher/:id", name: "voucher-edit", component: () => import("@/views/finance/voucher/voucher-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Recap Journal
      { path: "/finance/recap-journal", name: "finance-recap-journal", component: () => import("@/views/finance/recap-journal/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Transfer (mutasi kas)
      { path: "/finance/cash-bank-transfer", name: "finance-cash-bank-transfer", component: () => import("@/views/finance/cash-bank/transfer/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/cash-bank-transfer/new", name: "finance-cash-bank-transfer-new", component: () => import("@/views/finance/cash-bank/transfer/transfer-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/cash-bank-transfer/:id", name: "finance-cash-bank-transfer-edit", component: () => import("@/views/finance/cash-bank/transfer/transfer-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Bank
      { path: "/finance/bank", name: "finance-bank", component: () => import("@/views/finance/cash-bank/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/bank/new", name: "finance-bank-new", component: () => import("@/views/finance/cash-bank/cash-bank-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/bank/:id", name: "finance-bank-edit", component: () => import("@/views/finance/cash-bank/cash-bank-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Cash
      { path: "/finance/cash", name: "finance-cash", component: () => import("@/views/finance/cash-bank/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/cash/new", name: "finance-cash-new", component: () => import("@/views/finance/cash-bank/cash-bank-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/cash/:id", name: "finance-cash-edit", component: () => import("@/views/finance/cash-bank/cash-bank-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Reconcile
      { path: "/finance/reconcile", name: "finance-cash-bank-reconcile", component: () => import("@/views/finance/cash-bank/cash-bank-reconcile.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Fixed Asset Type
      { path: "/finance/fixed-asset/type", name: "finance-fixed-asset-type", component: () => import("@/views/finance/fixed-asset-type/index.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/fixed-asset/type/new", name: "finance-fixed-asset-type-new", component: () => import("@/views/finance/fixed-asset-type/fixed-asset-type-add.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/fixed-asset/type/:id", name: "finance-fixed-asset-type-edit", component: () => import("@/views/finance/fixed-asset-type/fixed-asset-type-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Fixed Asset
      { path: "/finance/fixed-asset", name: "finance-fixed-asset", component: () => import("@/views/finance/fixed-asset/index.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/fixed-asset/new", name: "finance-fixed-asset-new", component: () => import("@/views/finance/fixed-asset/fixed-asset-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      { path: "/finance/fixed-asset/:id", name: "finance-fixed-asset-edit", component: () => import("@/views/finance/fixed-asset/fixed-asset-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Finance' } },
      // Receipt
      { path: "/inventory/receipt", name: "inventory-receipt", component: () => import("@/views/inventory/receipt/index.vue"), meta: { requiresAuth: true, permission: "receive-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/receipt/new", name: "inventory-receipt-new", component: () => import("@/views/inventory/receipt/receipt-add.vue"), meta: { requiresAuth: true, permission: "receive-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/receipt/:id", name: "inventory-receipt-edit", component: () => import("@/views/inventory/receipt/receipt-edit.vue"), props: true, meta: { requiresAuth: true, permission: "receive-menu", hide: true, group: 'Inventory' } },
      // Delivery
      { path: "/inventory/delivery", name: "inventory-delivery", component: () => import("@/views/inventory/delivery/index.vue"), meta: { requiresAuth: true, permission: "delivery-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/delivery/new", name: "inventory-delivery-new", component: () => import("@/views/inventory/delivery/delivery-add.vue"), meta: { requiresAuth: true, permission: "delivery-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/delivery/:id", name: "inventory-delivery-edit", component: () => import("@/views/inventory/delivery/delivery-edit.vue"), props: true, meta: { requiresAuth: true, permission: "delivery-menu", hide: true, group: 'Inventory' } },
      // Retur
      { path: "/inventory/retur", name: "inventory-retur", component: () => import("@/views/inventory/retur/index.vue"), meta: { requiresAuth: true, permission: "delivery-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/retur/new", name: "inventory-retur-new", component: () => import("@/views/inventory/retur/retur-add.vue"), meta: { requiresAuth: true, permission: "delivery-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/retur/:id", name: "inventory-retur-edit", component: () => import("@/views/inventory/retur/retur-edit.vue"), props: true, meta: { requiresAuth: "delivery-menu", permission: null, hide: true, group: 'Inventory' } },
      // warranty
      { path: "/inventory/warranty", name: "inventory-warranty", component: () => import("@/views/inventory/warranty/index.vue"), meta: { requiresAuth: true, permission: "warranty-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/warranty/new", name: "inventory-warranty-new", component: () => import("@/views/inventory/warranty/warranty-add.vue"), meta: { requiresAuth: true, permission: "warranty-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/warranty/:id", name: "inventory-warranty-edit", component: () => import("@/views/inventory/warranty/warranty-edit.vue"), props: true, meta: { requiresAuth: true, permission: "warranty-menu", hide: true, group: 'Inventory' } },
      // Warranty
      { path: "/inventory/adjust", name: "inventory-adjust", component: () => import("@/views/inventory/adjust/index.vue"), meta: { requiresAuth: true, permission: "adjust-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/adjust/new", name: "inventory-adjust-new", component: () => import("@/views/inventory/adjust/adjust-add.vue"), meta: { requiresAuth: true, permission: "adjust-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/adjust/:id", name: "inventory-adjust-edit", component: () => import("@/views/inventory/adjust/adjust-edit.vue"), props: true, meta: { requiresAuth: true, permission: "adjust-menu", hide: true, group: 'Inventory' } },
      // Summary
      { path: "/inventory/summary", name: "inventory-summary", component: () => import("@/views/inventory/module/summary-table.vue"), meta: { requiresAuth: true, hide: true, group: 'Inventory' } },
      { path: "/inventory/summary-unit", name: "inventory-summary-unit", component: () => import("@/views/inventory/module/summary-unit-table.vue"), meta: { requiresAuth: true, hide: true, group: 'Inventory' } },
      // Transfer Unit
      { path: "/inventory/transfer-unit", name: "inventory-transfer-unit", component: () => import("@/views/inventory/transfer-unit/index.vue"), meta: { requiresAuth: true, permission: "transfer-unit-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/transfer-unit/new", name: "inventory-transfer-unit-new", component: () => import("@/views/inventory/transfer-unit/transfer-add.vue"), meta: { requiresAuth: true, permission: "transfer-unit-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/transfer-unit/:id", name: "inventory-transfer-unit-edit", component: () => import("@/views/inventory/transfer-unit/transfer-edit.vue"), props: true, meta: { requiresAuth: true, permission: "transfer-unit-menu", hide: true, group: 'Inventory' } },

      { path: "/inventory/receive-unit", name: "inventory-receive-unit", component: () => import("@/views/inventory/receive-unit/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Inventory' } },
      { path: "/inventory/receive-unit/:id", name: "inventory-receive-unit-edit", component: () => import("@/views/inventory/receive-unit/receive-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Inventory' } },

      // Transfer
      { path: "/inventory/transfer", name: "inventory-transfer", component: () => import("@/views/inventory/transfer/index.vue"), meta: { requiresAuth: true, permission: "transfer-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/transfer/new", name: "inventory-transfer-new", component: () => import("@/views/inventory/transfer/transfer-add.vue"), meta: { requiresAuth: true, permission: "transfer-menu", hide: true, group: 'Inventory' } },
      { path: "/inventory/transfer/:id", name: "inventory-transfer-edit", component: () => import("@/views/inventory/transfer/transfer-edit.vue"), props: true, meta: { requiresAuth: true, permission: "transfer-menu", hide: true, group: 'Inventory' } },
      // Shipping
      { path: "/shipping", name: "shipping", component: () => import("@/views/shipping/index.vue"), meta: { requiresAuth: true, permission: "shipping-menu", hide: true, group: 'Shipping' } },
      { path: "/shipping/new", name: "shipping-new", component: () => import("@/views/shipping/shipping-add.vue"), meta: { requiresAuth: true, permission: "shipping-menu", hide: true, group: 'Shipping' } },
      { path: "/shipping/:id", name: "shipping-edit", component: () => import("@/views/shipping/shipping-edit.vue"), props: true, meta: { requiresAuth: true, permission: "shipping-menu", hide: true, group: 'Shipping' } },
      // bill
      { path: "/bill", name: "bill", component: () => import("@/views/invoice/bill/index.vue"), meta: { requiresAuth: true, hide: true } },
      { path: "/bill/new", name: "bill-new", component: () => import("@/views/invoice/bill/bill-add.vue"), meta: { requiresAuth: true, hide: true } },
      // { path: "/bill/:id", name: "bill-edit", component: () => import("@/views/invoice/bill/bill-edit.vue"), props: true, meta: { requiresAuth: true }},
      // Invoice
      { path: "/invoice", name: "invoice", component: () => import("@/views/invoice/index.vue"), meta: { requiresAuth: true, permission: "invoice-menu", hide: true, group: 'Invoice' } },
      { path: "/invoice/new", name: "invoice-new", component: () => import("@/views/invoice/invoice-add.vue"), meta: { requiresAuth: true, permission: "invoice-menu", hide: true, group: 'Invoice' } },
      { path: "/invoice/:id", name: "invoice-edit", component: () => import("@/views/invoice/invoice-edit.vue"), props: true, meta: { requiresAuth: true, permission: "invoice-menu", hide: true, group: 'Invoice' } },
      { path: "/invoice/list-outstanding", name: "invoice-list-outstanding", component: () => import("@/views/invoice/module/report-outstanding.vue"), meta: { requiresAuth: true, hide: true, group: 'Invoice' } },
      // Retur
      { path: "/retur", name: "retur", component: () => import("@/views/retur/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Invoice' } },
      { path: "/retur/new", name: "retur-new", component: () => import("@/views/retur/retur-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Invoice' } },
      { path: "/retur/:id", name: "retur-edit", component: () => import("@/views/retur/retur-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Invoice' } },
      // Leasing
      { path: "/leasing", name: "leasing", component: () => import("@/views/leasing/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/leasing/new", name: "leasing-new", component: () => import("@/views/leasing/leasing-add.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      { path: "/leasing/:id", name: "leasing-edit", component: () => import("@/views/leasing/leasing-edit.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // Lock
      { path: "/lock/", name: "lock", component: () => import("@/views/lock/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
      // { path: "/lock/approval", name: "lock", component: () => import("@/views/lock/modules/approval-table.vue"), meta: { requiresAuth: true }},
      // Approval
      { path: "/approval", name: "approval", component: () => import("@/views/approval/index.vue"), meta: { requiresAuth: true, hide: true, group: 'Master' } },
    ],
  },
  // Workshop Pageview
  { path: "/workshop/page-view/:id", name: "workshop-page-view", component: () => import("@/views/workshop/page-view.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Workshop' } },
  // Order Pageview
  { path: "/purchase/order/page-view/:id", name: "order-page-view", component: () => import("@/views/order/page-view.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Purchase' } },
  // Invoice Pageview
  { path: "/invoice/page-view/:id", name: "invoice-page-view", component: () => import("@/views/invoice/page-view.vue"), props: true, meta: { requiresAuth: true, hide: true, group: 'Invoice' } },
  // Public View
  { path: "/public/workshop/:token", name: "workshop-public-view", component: () => import("@/views/public-view/workshop-view.vue"), props: true, meta: { requiresAuth: false, hide: true, group: 'Public' } },
  { path: "/public/order/:token", name: "order-public-view", component: () => import("@/views/public-view/order-view.vue"), props: true, meta: { requiresAuth: false, hide: true, group: 'Public' } },
  { path: "/public/invoice/:token", name: "invoice-public-view", component: () => import("@/views/public-view/invoice-view.vue"), props: true, meta: { requiresAuth: false, hide: true, group: 'Public' } },
  // Other
  { path: '/:pathMatch(.*)*', name: 'error-page-404', component: () => import("@/views/page404.vue") },
  { path: '/forbidden', name: 'forbidden-page-403', component: () => import("@/views/page403.vue") },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})
router.beforeEach((to, from, next) => {
  const titleText = to.name;
  const words = titleText.split("-");

  // const wordslength = words.length;
  // for (let i = 0; i < wordslength; i++) {
  //   words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  //   console.log('words: ')
  //   console.log(words[i])
  // }
  document.title = "Motion  - " + words[0].toUpperCase();
  if (to.meta.requiresAuth === false) {
    next()
  } else {
    console.log('to.meta')
    console.log(to.meta.hasOwnProperty('permission'))
    const authUser = JSON.parse(window.localStorage.getItem('authUser'))
    if (authUser === null) {
      next({ name: 'login' })
    } else {
      const permission = authUser.user.permission
      if (to.meta.hasOwnProperty('permission')) {
        if (authUser.user.permission[to.meta.permission]) {
          next()
        } else {
          next({ name: 'forbidden-page-403' })
        }
      } else {
        next()
      }
    }
  }
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
routes[1].children